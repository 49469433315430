.textContainer {
  display: flex;
  text-align: left;
  flex-direction: column;
  /* margin-left: 100px; */
  color: white;
  /* height: 60%; */
  /* justify-content: left; */
}

.activeContainer {
  display: flex;
  align-items: left;
  margin-bottom: 50%;
  background-color: navy;
  width: 400px;
  margin-top: 20px;
}

.activeItem {
  display: flex;
  align-items: center;
  justify-content: left;
}

.activeContainer img {
  padding-left: 10px;
}

.textContainer h1 {
  margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 1200px) {
  /* .textContainer {
    display: none;
  } */
}
