.outerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../../assets/beehive.png);
  background-size: cover;
}

.container {
  margin-top: 20px;
  /* margin-bottom: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 1px;
  height: 60%;
  width: 35%;
}

@media (min-width: 320px) and (max-width: 480px) {
  /* .outerContainer {
    height: 100%;
  } */

  .container {
    width: 100%;
    /* height: 100%; */
  }
}

@media (min-width: 480px) and (max-width: 1200px) {
  .container {
    width: 90%;
  }
}
