html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

#root {
  height: 100vh;
}

* {
  box-sizing: border-box;
}

.joinOuterContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100vh;
  align-items: center;
  /* background-color: #1a1a1d; */
  background: url(../../assets/beehive.png);
  background-size: cover;
}

.joinInnerContainer {
  width: 25%;
  background-color: #fff;
  /* border-radius: 7%; */
  border: 6px solid navy;
}

.joinInput {
  border-radius: 0;
  padding: 15px 20px;
  width: 100%;
  background-color: rgb(255, 215, 103);
}

.heading {
  color: rgb(255, 215, 103);
  font-weight: 800;
  font-family: Georgia;
  background-color: navy;
  font-size: 2em;
  padding-bottom: 10px;
}

.button {
  color: #fff !important;
  text-transform: uppercase;
  border-radius: 0%;
  text-decoration: none;
  background: navy;
  padding: 20px;
  display: inline-block;
  border: none;
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

@media (min-width: 320px) and (max-width: 480px) {
  /* .joinOuterContainer {
    height: 100%;
  } */

  .joinInnerContainer {
    width: 90%;
  }
}

button:focus {
  outline: 0;
}
